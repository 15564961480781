:root {
    --font-mono: Menlo, Courier, monospace;
    --font-title: Roboto, Menlo, Courier, monospace;
}

html, body {
    font-family: "Helvetica Neue", Helvetica;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 0;
    padding: 0;
}

nav ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav li {
    display: inline-block;
}

#root {
    height: 100%;
    width: 100%;
}
