:root {
    --console-border: 2px solid #eee;
    --controls-height: 2.5em;
    --panes-height: calc(100vh - var(--controls-height));
    --app-accent: rgba(119, 0, 136);
    --app-controls-color: #222;
    --app-controls-background: #fff;
    --app-controls-background-light: rgba(224, 224, 224, 0.5);
    --app-controls-highlight: rgba(119, 0, 136, 0.1);
    --app-title-highlight: rgba(199, 146, 234);
    --app-accent-alternate: rgba(50, 0, 135);
}

@media screen and (prefers-color-scheme: dark) {
    :root {
        --console-border: 2px solid #181818;
        --app-accent: rgba(199, 146, 234);
        --app-controls-color: #fff;
        --app-controls-background: #111;
        --app-controls-background-light: rgba(32, 32, 32, 0.5);
        --app-controls-highlight: rgba(199, 146, 234, 0.1);
        --app-accent-alternate: rgb(154, 145, 235);
    }
}

pre, textarea {
    letter-spacing: 0.04em;
    line-height: 1.25em;
    tab-size: 4;
}

h1 pre, h2 pre, h3 pre, h4 pre, h5 pre, h6 pre {
    font-weight: 800;
}

#app {
    height: 100%;
}

h1.app-title {
    font-size: 1.1em;
    cursor: default;
    user-select: none;

    margin: 0;
    padding-right: 0.8em;
    margin-right: 0.5em;
    border-right: 1px solid #ddd;
}

.app-title {
    font-family: var(--font-title);
    font-weight: 400;
    letter-spacing: 0.03em;
    margin: 0 0.3em;
}

.app-title-hack {
    display: inline-block;
}

.app-title-hack {
    color: #f07178;
}

.app-title-pad {
    color: var(--app-title-highlight);
}

@media screen and (prefers-color-scheme: dark) {
    h1.app-title {
        border-right: 1px solid rgb(45, 45, 45);
    }

}

.console {
    display: flex;
    flex-direction: column;
}

.console-output {
    position: relative;
    overflow: auto;
    border-radius: 0.25em;
    border-color: #000;
    padding: 0.5em 0.75em;
    margin: 0;
}

.console-output .stderr {
    color: #a00;
}

.console-output .note {
    color: #00a;
    position: absolute;
    right: 0;
    transform: translateY(calc(-100% + 0.1em));
    padding: 0.1em;
    padding-left: 0.5em;
    background-color: rgba(80, 80, 80, 0.1);
    border-radius: 0.5em;
}

.terminal {
    padding: 0.4em;
}

@media screen and (prefers-color-scheme: dark) {
    body, textarea, button {
        background-color: rgb(33, 33, 33);
        color: #eee;
    }

    button {
        border: 1px solid;
        border-radius: 0.2em;
    }

    .console {
        border-top-color: #eee;
    }

    .console-output .stderr {
        color: #ff5050;
        font-weight: bolder;
    }

    .console-output .note {
        color: #88f;
        background-color: rgba(80, 80, 80, 0.9);
    }
}
