:root {
    --tab-background: rgb(240, 240, 240);
    --tab-bar: transparent;
    --tab-active: #fff;
    --tab-hover: rgb(250, 250, 250);
    --tab-highlight: rgb(170, 170, 170);
    --control-color: var(--tab-highlight);
    --control-hover: #111;
    --control-hover-background: rgb(220, 220, 220);

    --tab-bar-height: 2.3m;
}

@media screen and (prefers-color-scheme: dark) {
    :root {
        --tab-background: rgb(20, 20, 20);
        --tab-bar: transparent;
        --tab-active: rgb(33, 33, 33);
        --tab-hover: rgb(40, 40, 40);
        --tab-highlight: rgb(170, 170, 170);
        --control-color: var(--tab-highlight);
        --control-hover: #fff;
        --control-hover-background: rgb(70, 70, 70);
    }
}

.tab-bar {
    background-color: var(--tab-background);
    height: var(--tab-bar-height);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.tab-bar button {
    cursor: pointer;
    margin: 0;
    outline: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.tab-bar .tab-button {
    font-size: 1em;
    height: var(--tab-bar-height);
    box-sizing: border-box;
    padding: 0.5em 1.25em;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    background-color: var(--tab-bar);
    cursor: pointer;
}

.tab-bar .tab-button:hover {
    border-bottom: 1px solid var(--tab-highlight);
    background-color: var(--tab-hover);
}

.tab-bar .tab-button.active {
    background-color: var(--tab-active);
}

.tab-bar .tab-button.active:hover {
    background-color: var(--tab-hover);
}

.tab-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tabs {
    flex-grow: 1;
    overflow: hidden;
}

.tab {
    visibility: hidden;
    position: absolute;
    overflow: hidden;
    height: 100%;
}

.tab.active {
    visibility: visible;
    position: unset;
}

button.tab-new {
    display: block;
    background: none;
    border: none;
    color: var(--control-color);
    margin: 0.4em;
    text-align: center;
    font-weight: 800;
    font-family: sans-serif;
    font-size: 0.8em;
    height: 2.2em;
    width:  2.2em;
    border-radius: 0.25em;
}

.tab-new:hover {
    background-color: var(--control-hover-background);
    color: var(--control-hover);
}

.tab-bar .tab-close {
    display: inline-block;
    border: none;
    background: none;
    color: var(--control-color);
    margin-left: 0.3em;
    margin-right: -0.5em;
}

.tab-bar .tab-close:hover {
    color: var(--control-hover);
    background-color: var(--control-hover-background);
}

.tab-bar .tab-close {
    font-size: 0.8em;
    height: 1.8em;
    width:  1.8em;
    border-radius: 0.25em;
}
