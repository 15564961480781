.app-loading {
    --app-loading-size: 6rem;

    z-index: 1000;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.4);
}

@media screen and (prefers-color-scheme: dark) {
    .app-loading {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.app-loading .app-loading-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-loading .app-loading-spinner {
    position: relative; /* enable percentage to be absolutely centered */
    font-size: var(--app-loading-size);
    width: var(--app-loading-size);
    height: var(--app-loading-size);
    margin: 1.5rem;
}

.app-loading p {
    text-align: center;
    margin: 0.25rem;
    padding: 0;
}

.app-loading-percentage {
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(calc(-50% + 0.25rem));
    font-style: italic;
}
